<template>
  <div class="pt-10">
    <div class="vx-col w-full mb-base">
      <vs-alert color="danger" title="User Not Found" :active.sync="userNotFound">
        <span>User record with id: {{ $route.params.userId }} not found. </span>
        <span>
                        <span>Check </span><router-link :to="{name:'list-admin'}" class="text-inherit underline">All Users</router-link>
                     </span>
      </vs-alert>

      <vx-card v-if="userData">
        <h2>Update Admin User</h2>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" class="w-full lg:w-3/4">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2 md:pr-5">
            <div class="w-full mb-5">
              <vs-input
                :success="
                !errors.first('fullName') && userData.fullName != ''
              "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('fullName') ? true : false"
                v-validate="'required|alpha_spaces|max:150'"
                name="fullName"
                data-vv-as="fullName"
                label="Full Name"
                icon-no-border
                icon="icon icon-user"
                icon-pack="feather"
                class="w-full"
                v-model="userData.fullName"
              />
              <span class="text-danger text-sm">{{ errors.first("fullName") }}</span>
            </div>
          </vs-col>
          <!--<div class="vx-row mb-6">
              <div class="vx-col w-full">
                  <vs-input class="w-full" name="firstName" v-validate="'required|alpha'" label="First Name"
                            v-model="userData.firstName"/>
                  <span class="text-danger text-sm" v-show="errors.has('firstName')">{{ errors.first('firstName') }}</span>
              </div>
          </div>
          <div class="vx-row mb-6">
              <div class="vx-col w-full">
                  <vs-input class="w-full" name="lastName" v-validate="'required|alpha'" label="Last Name"
                            v-model="userData.lastName"/>
                  <span class="text-danger text-sm"
                        v-show="errors.has('lastName')">{{ errors.first('lastName') }}</span>
              </div>
          </div>-->
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2 md:pl-5">
            <div class="w-full mb-5">
              <vs-input
                :success="!errors.first('email') && userData.email != ''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('email') ? true : false"
                v-validate="'required|email'"
                name="email"
                data-vv-as="email"
                label="Email"
                icon-no-border
                icon="icon icon-mail"
                icon-pack="feather"
                class="w-full"
                v-model="userData.email"
              />
              <span class="text-danger text-sm">{{ errors.first("email") }}</span>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
            <div class="w-full mb-5">
              <label class="vs-input--label">Account Status</label>
              <vs-switch v-model="status"/>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
            <div class="w-full mb-5">
              <vs-button class="mr-3 mb-2" @click.prevent="submitForm">Submit</vs-button>
              <vs-button class="mb-2" color="danger"
                         @click="userData.fullName = userData.email = ''">Reset
              </vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vx-card>
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  //import moduleAdminUserManagement from '@/store/admin-user/moduleAdminUserManagement.js'

  export default {
    data() {
      return {
        status:false,
        userData: {
          fullName:"",
          email:"",
          status:false,
        },
        userNotFound: false
      }
    },
    methods: {
      ...mapActions("adminUser", [
        "updateAdminUser",
        "fetchAdminUserById"
      ]),
      showUpdateSuccess() {
        this.$vs.notify({
          title: 'Success',
          text: "User Updated Successfully",
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })
      },
      submitForm() {
        this.$validator.validateAll().then(result => {
          if (result) {
            // if form have no errors
            this.$vs.loading()
            this.userData.status = this.status;
            this.updateAdminUser(this.userData)
              .then(() => {
                this.$vs.loading.close()
                this.showUpdateSuccess()
                this.$router.push({ name: 'list-admin' });
              })
              .catch(err => {
                this.$vs.loading.close()
                if (err.response.status === 422) {
                  this.errors.add({
                    field: "email",
                    msg: err.response.data.message
                  });
                } else {
                  this.$vs.notify({
                    title: 'Failure',
                    text: "Create Admin Failed",
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                  })
                }
              })
          } //else {
          //}
        })
      },
      fetchUserById(userId) {
        this.fetchAdminUserById(userId)
          .then(res => {
            this.userData = res.data.data;
            //this.userData.status = (res.data.data.accountStatus == "Active")?true:false
            this.status = (res.data.data.accountStatus == "Active")?true:false
            //console.log(this.userData.status);
          })
          .catch(err => {
            if (err.response.status === 404) {
              this.userNotFound = true
              return
            }
            console.error(err)
          })
      }
    },
    created: function () {
      /*if (!moduleAdminUserManagement.isRegistered) {
        this.$store.registerModule('adminUserManagement', moduleAdminUserManagement)
        moduleAdminUserManagement.isRegistered = true
      }*/
      this.fetchUserById(this.$route.params.id)
    },
  }
</script>
